// LIVE DOMAIN
// export const API_URL = 'https://sublime.beyonderissolutions.com/api';

// STAGING DOMAIN
export const API_URL = process.env.REACT_APP_API_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

// LOCAL DOMAIN
// export const API_URL = 'http://192.168.100.76:8000/api';

// BOOKING STATUS CONSTANTS
export const CONFIRMED = 'confirmed';
export const PENDING = 'pending';
export const CANCELLED = 'cancelled';
export const UNCONFIRMED = 'unconfirmed';

// CUSTOMER SERVICE REPORT CONSTANTS
export const CLOSED_REPORT = 'closed';
export const PENDING_REPORT = 'pending';
